import React from 'react';
import Helmet from "react-helmet";
import Header from "../components/Header";
import Footer from "../components/Footer";
import {useStaticQuery, graphql} from "gatsby"

const MainLayout = ({children}) => {
    const data = useStaticQuery(graphql`
        query HeaderQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `);
    return <>
        <Helmet>
            <title>{data.site.siteMetadata.title}</title>
            <meta name="title" content="Pigeon | Patient Text Messaging For Medical Providers"/>
            <meta name="description"
                  content="Communicate directly and securely with your patients through encrypted, HIPAA-compliant text messaging"/>

            {/*Facebook      */}
            <meta property="og:type" content="website"/>
            <meta property="og:url" content="https://pigeonmedical.com/"/>
            <meta property="og:title" content="Pigeon | Patient Text Messaging For Medical Providers"/>
            <meta property="og:description"
                  content="Communicate directly and securely with your patients through encrypted, HIPAA-compliant text messaging"/>
            <meta property="og:image" content="https://pigeonmedical.com/images/social.png"/>

            {/*Twitter      */}
            <meta property="twitter:card" content="summary_large_image"/>
            <meta property="twitter:url" content="https://pigeonmedical.com/"/>
            <meta property="twitter:title" content="Pigeon | Patient Text Messaging For Medical Providers"/>
            <meta property="twitter:description"
                  content="Communicate directly and securely with your patients through encrypted, HIPAA-compliant text messaging"/>
            <meta property="twitter:image" content="https://pigeonmedical.com/images/social.png"/>


            <link rel="icon" href="favicons/favicon.ico"/>
            <link href="https://assets.calendly.com/assets/external/widget.css" rel="stylesheet"/>
        </Helmet>
        <Header/>
        {children}
        <Footer/>
    </>
}

export default MainLayout