import React from 'react';
import {useSelector} from "react-redux";
import {Navbar} from "react-bootstrap";
import {Link} from "gatsby"
import logo from '../assets/common/pigeon-logo-white.svg'

const renderNavItem = ({title, href}, index) => {
    return (
        <li className="text-center d-flex align-items-center" key={`nav-li-${index}`}>
            <Link className="nav-link" to={href}>{title}</Link>
        </li>
    );
}

const Header = () => {
    const nav = useSelector(state => state.nav.items);

    return (
        <header>
            <Navbar bg="transparent" expand="lg" variant="dark">
                <div className="container">
                    <Navbar.Brand href="#">
                        <img src={logo} alt="Pigeon" width={115} height={92}/>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="main-nav"/>
                    <Navbar.Collapse id="main-nav">
                        <ul className="navbar-nav ml-auto pt-3 pt-lg-0">{
                            nav.length ? nav.map((item, index) => renderNavItem(item, index)) : <></>
                        }</ul>
                        <form className="form-inline ml-lg-3">
                            <Link to="https://app.pigeonhealth.com/app/login" className="btn btn-sm btn-light my-2">Login</Link>
                        </form>
                    </Navbar.Collapse>
                </div>
            </Navbar>
        </header>
    )
}

export default Header