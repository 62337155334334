import React from 'react';
import {useSelector} from "react-redux";
import logo from "../assets/common/pigeon-logo-white.svg";
import {Link} from "gatsby";
import {Container} from "react-bootstrap";
import Typography from "./common/Typography";


const renderNavItem = ({title, href}, index) => {
    return (
        <li className="text-center d-flex align-items-center" key={`nav-a-${index}`}>
            <Link className="nav-link text-white" to={href}>{title}</Link>
        </li>
    );
}

const Footer = () => {
    const nav = useSelector(state => state.nav.items);
    const currentYear = new Date().getFullYear();
    return <footer>
        <div className="footer-nav bg-dark">
            <Container>
                <nav className="nav justify-content-center align-items-center flex-column flex-md-row">
                    <Link className="nav-link" to="/#">
                        <img src={logo} alt="Pigeon" width={69} height={55}/>
                    </Link>
                    { nav.length && nav.map((item, index) => renderNavItem(item, index)) }
                </nav>
            </Container>
        </div>
        <div className="legal">
            <Container className="py-3 text-white text-center text-uppercase">
                <Typography component="span" className="mr-3">Copyright © 2017-{currentYear} Pigeon Medical Technologies, Inc.</Typography>
                <Link className="text-white" to="/privacy"><u>Privacy</u></Link>
            </Container>
        </div>
    </footer>
}

export default Footer